import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const RegisterService = {
  callRegisterFields: async () => {
    return await callApi("register");
  },
  callRegisterFieldCheck: async (field, value) => {
    return await callApi(
        `register-field-verification?field=${field}&value=${value}`
    );
  },
  callTransPassCheck: async (data) => {
    return API.post("check-transaction-password", data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  },
  callRegisterUser: async (data) => {
    return API.post("register", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  },
  callBankUpload: async (data, username, type) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("username", username);

    try {
      return (await API.post(`upload-bank-receipt?type=${type}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
    } catch (error) {
      return error.response.data;
    }
  },
  deleteBankReceipt: async (data) => {
    const response = await API.post(`remove-bank-receipt`, data);
    return response?.data;
  },
  callEcomRegisterLink: async (data) => {
    return await callApi(
        `ecom-register-link?regFromTree=${data.regFromTree}&position=${data.position}&placement=${data.placement}`
    );
  },
  callEcomStoreLink: async () => {
    return await callApi(`ecom-store-link`);
  },
  callLetterPreview: async (username) => {
    return await callApi(`letter-preview?username=${username}`);
  },
  createStripePaymentIntent: async (body, type) => {
    const response = await API.post(`create-stripe-payment-intent?type=${type}`, body);
    return response.data
  },
  updateStripePaymentIntent: async (body, type) => {
    return await API.post(`update-stripe-payment-intent?type=${type}`, body);
  },
  cancelStripePaymentIntent: async (body) => {
    const response = await API.post(`cancel-stripe-payment-intent`, body);
    return response.data;
  }
};
