import API from "../../api/api";

const ProfileService = {
  getProfile: async () => {
    return API.get("/profile-view")
      .then((response) => response?.data?.data)
      .catch((error) => console.log(error.message));
  },
  setPersonalData: async (data) => {
    return API.patch("/update-personal-details", data)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  setContactDetails: async (data) => {
    return API.patch("/update-contact-details", data)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  updateBankDetails: async (data) => {
    return API.patch("/update-bank-details", data)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  updateUserProfilePic: async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    const type = "profile";

    try {
      return (await API.post(`update-avatar?type=${type}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
    } catch (error) {
      return error.response.data;
    }
  },
  updateAdditionalDetails: async (data) => {
    return API.patch("/update-additionalData", data)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  updatePaymentDetails: async (data) => {
    return API.patch("update-payment-details", data)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  getKycDetails: async () => {
    return API.get("kyc-details")
      .then((response) => response?.data?.data)
      .catch((error) => console.log(error.message));
  },
  getkycUploads: async (files, category, type) => {
    const formData = new FormData();

    [...files].forEach((file, i) => {
      formData.append(`file`, file, file.name);
    });

    try {
      return (await API.post(`kyc-upload?category=${category}&type=${type}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  deleteKycFile: async (filesId) => {
    const payload = { kycArr: filesId };
    return API.post(`kyc-delete`, payload)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  deleteProfileAvatar: async () => {
    return API.patch(`remove-avatar`)
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  changePassword: async (body) => {
    return API.patch(`change-user-password`, JSON.stringify(body))
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  changeTransactionPassword: async (body) => {
    return API.patch(`change-transaction-password`, JSON.stringify(body))
      .then((response) => response.data)
      .catch((error) => console.log(error.message));
  },
  getStripeAccountId: async () => {
    return API.post("create-stripe-account")
        .then((response) => response?.data)
        .catch((error) => console.log(error.message));
  },
};

export default ProfileService;
